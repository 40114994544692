import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import SEO from "../components/seo";

export default function Home() {
  const [isLoading, setLoading] = useState(true);
  const [reviewData, setReviewData] = useState<any>({});

  useEffect(() => {
    const unsubs = async () => {
      await fetch("https://api.rupiah.trade/review/google", {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          setReviewData(result);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    };

    unsubs();
  }, []);
  return (
    <>
      <SEO title="Testimoni - Rupiah.trade" />
      <Nav />
      <div
        data-aos="fade-up"
        id="rate"
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      >
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Testimoni
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="7e5e8ff8-1960-4094-a63a-2a0c0f922d69"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#7e5e8ff8-1960-4094-a63a-2a0c0f922d69)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative text-primary-700">Ulasan pengguna</span>
            </span>{" "}
            Rupiah.trade
          </h2>
          <div className="flex sm:justify-center items-center">
            {Array.from({ length: reviewData?.place_info?.rating || 0 }).map(
              (_star, index) => (
                <React.Fragment key={index}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 $ fill-yellow-700`}
                    role="img"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453l-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107l-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4l4.536-4.082c.297-.268.406-.686.278-1.065z" />
                  </svg>
                </React.Fragment>
              )
            )}
            {(reviewData?.place_info?.rating || 0) % 1 !== 0 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6  fill-yellow-700`}
                role="img"
                viewBox="0 0 24 24"
              >
                <path d="M5.025 20.775A.998.998 0 0 0 6 22a1 1 0 0 0 .555-.168L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4l4.536-4.082a1 1 0 0 0-.59-1.74l-5.701-.454l-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.214 4.107l-1.491 6.452zM12 5.429l2.042 4.521l.588.047h.001l3.972.315l-3.271 2.944l-.001.002l-.463.416l.171.597v.003l1.253 4.385L12 15.798V5.429z" />
              </svg>
            )}
            <div className="ml-2 text-gray-500">
              {reviewData?.place_info?.rating || 0} dari{" "}
              {reviewData?.place_info?.reviews || 0} ulasan
            </div>
          </div>
        </div>
        <div className="grid max-w-md lg:max-w-screen-md sm:mx-auto">
          {isLoading &&
            Array.from({ length: 5 }).map((_el, index) => (
              <div
                key={index}
                className="my-2 h-24 rounded-xl bg-gray-200 shadow-sm flex gap-4 animate-pulse"
              ></div>
            ))}
          {reviewData.reviews?.map((review: any) => {
            return (
              <div
                key={review.user.link}
                className="my-2 p-5 rounded-xl bg-gray-100 shadow-sm flex gap-4"
              >
                <div className="w-10 min-w-max">
                  <img
                    height={40}
                    width={40}
                    className="h-10 w-10"
                    src={review.user.thumbnail}
                  ></img>
                </div>

                <div>
                  <h3 className="font-bold">
                    <a
                      href={review.user.link}
                      target="__blank"
                      className="hover:text-primary-700"
                    >
                      {review.user.name}
                    </a>
                  </h3>
                  <div>
                    {Array.from({ length: 5 }).map((_el, index) => (
                      <span
                        key={index}
                        className={
                          review.rating >= index + 1
                            ? "text-yellow-700"
                            : "text-gray-300"
                        }
                      >
                        ★
                      </span>
                    ))}
                    <span className="ml-2 text-xs text-gray-500 inline-block">
                      {review.date}
                    </span>
                  </div>

                  {review.snippet.includes("(Diterjemahkan oleh Google)") ? (
                    <p className="text-sm">
                      {review.snippet.substring(
                        review.snippet.indexOf("(Asli)") + 6
                      )}
                    </p>
                  ) : (
                    <p className="text-sm">{review.snippet}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full text-center">
          <a className="mx-auto" target="__blank" href="https://search.google.com/local/reviews?placeid=ChIJ3_WLAI-VUGkRZw9UAXQObsk">
            <button className="mt-8 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-xl bg-primary-700 hover:bg-primary-500 hover:shadow-2xl focus:shadow-outline focus:outline-none">
              Semua Ulasan
            </button>
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
}
